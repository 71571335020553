<aside id="sidebar" class="sidebar toggle-sidebar">
    <ul class="sidebar-nav" id="sidebar-nav">
        <li class="nav-item">
            <a
                class="nav-link"
                routerLink="dashboard/today"
                routerLinkActive="active"
                (click)="hideSidebar()"
            >
                <i class="bi bi-grid"></i>
                <span>Dashboard</span>
            </a>
        </li>
        <app-sidebar-items></app-sidebar-items>

        <li class="nav-heading nav-separator">
            <hr />
        </li>
        <li class="nav-item logout">
            <a class="nav-link collapsed" (click)="logout()">
                <i class="bi bi-box-arrow-left f-20px"></i>
                <span>Sair</span>
            </a>
        </li>
    </ul>
</aside>
