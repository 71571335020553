<header id="header" class="header fixed-top d-flex align-items-center">
    <div class="d-flex align-items-center justify-content-between">
        <a
            routerLink="dashboard/today"
            routerLinkActive="active"
            class="logo d-flex align-items-center"
        >
            <img class="logo" [src]="iconUrl" alt="" />
        </a>
        <i class="bi bi-list toggle-sidebar-btn" (click)="show()"></i>
    </div>

    <nav class="header-nav ms-auto">
        <ul class="d-flex align-items-center">
            <li class="nav-item dropdown pe-3">
                <a
                    class="nav-link nav-profile d-flex align-items-center pe-0"
                    data-bs-toggle="dropdown"
                >
                    <img
                        src="../../../../../../assets/icons/icon-user.svg"
                        alt="user"
                        class="rounded-circle"
                    />
                    <!-- adicionar 'dropdown-toggle' no span para funcionar o dropdown-->
                    <span
                        class="d-none d-md-block ps-2 f-neutral-3 f-14px lh-24px"
                    >
                        {{ user!.name }}
                    </span>
                </a>
            </li>
        </ul>
    </nav>
</header>
