import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-note',
    templateUrl: './note.component.html',
    styleUrl: './note.component.scss',
})
export class NoteComponent {
    @Input({ required: true }) text: string = '';
}
